'use client'
type Props = {
  error: Error & {digest?: string}
  reset: () => void
}
const GlobalError = ({error, reset}: Props) => {
  console.error(error)

  return (
    <html>
      <body>
        <h1>Something went wrong!</h1>
        <button onClick={() => reset()}>Try again</button>
      </body>
    </html>
  )
}
export default GlobalError
